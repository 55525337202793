import React, { Component } from 'react';
import service from '../../components/Date';
import 'bootstrap/dist/css/bootstrap.css';
import DataTable from 'react-data-table-component';
import { navigate } from '@reach/router';
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class MonitorizareAplicatii extends Component {
    static displayName = MonitorizareAplicatii.name;
    state = {
        dataStart: new Date(),
        dataStop: new Date(),
        listaAccesariAplicatii: [],
        popupVizibil: false,
        gridVizibil: false,
        aplicatieSelectata: null,
        filtru: null,
        legal: true,
        checked: 1,
        loaderVizibil: false
    }

    componentDidMount() {
        this.setState({ loaderVizibil: true });
        this.getDateAccesareAplicatii(this.getDataAzi());
    }

    getDataAzi = () => {
        var zi = new Date().getDate();
        var luna = new Date().getMonth() + 1;
        var an = new Date().getFullYear();
        return zi.toString() + "." + luna.toString() + "." + an;
    }

    getDateAccesareAplicatii = (data) => {
        service.getDateAccesareAplicatii(data).then(r => {
            if (r && r.listaAccesariAplicatii) {
                this.setState({
                    listaAccesariAplicatii: r.listaAccesariAplicatii,
                    gridVizibil: true,
                    loaderVizibil: false
                });
            }
        })
            .catch(error => {//in caz de eroare
                alert("Nu aveţi drepturile necesare pentru a accesa aceste resurse.");
                this.setState({ gridVizibil: false, loaderVizibil: false }, () => { navigate('./video-manual'); })
            })
    }

    handleSelectRow = (date) => {
        this.setState({ aplicatieSelectata: date, popupVizibil: true }, () => {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "get",
                url: "/api/Monitorizare/GetTrace/aplicatii/" + this.state.aplicatieSelectata.idAccesariAplicatii
            }).then((raspuns) => {
                if (raspuns)
                    this.setState({
                        trace: raspuns.data
                    })
            }
            ).catch((error) => {
                console.log(error);
            });
        })
    }

    inchidePopup = () => {
        this.setState({ popupVizibil: false });
    }

    afiseazaPopup = () => {
        if (this.state.aplicatieSelectata) {
            this.setState({ popupVizibil: true });
        } else {
            alert("Selectaţi o linie din tabel!");
        }
    }

    handleButonPerioadaClick = () => {
        if (this.state.dataStart === null) {
            alert("Data de început a intervalului nu este completată.");
            return;
        }
        if (this.state.dataStop === null) {
            alert("Data de sfârşit a intervalului nu este completată.");
            return;
        }

        this.setState({ loaderVizibil: true });

        //acceseaza API
        service.getDateAccesareAplicatiiPerioada(this.formatDate(this.state.dataStart), this.formatDate(this.state.dataStop)).then(r => {
            if (r && r.listaAccesariAplicatii) {
                this.setState({
                    listaAccesariAplicatii: r.listaAccesariAplicatii,
                    gridVizibil: true,
                    loaderVizibil: false
                });
            }
        })
            .catch(error => {//in caz de eroare
                alert("Nu aveţi drepturile necesare pentru a accesa aceste resurse.");
                this.setState({ gridVizibil: false, loaderVizibil: false }, () => { navigate('./video-manual'); })
            })
    }

    handleButonResetPerioadaClick = () => {
        this.setState({
            dataStart: new Date(),
            dataStop: new Date(),
            loaderVizibil: true
        }, () => { this.getDateAccesareAplicatii(this.getDataAzi()); });
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-') + ' 00:00:00';
    }

    render() {
        //console.log("Monitorizare aplicatii State", this.state);

        var listaDeAfisat = this.state.listaAccesariAplicatii;

        if (this.state.listaAccesariAplicatii) {
            if (this.state.filtru)
                listaDeAfisat = listaDeAfisat.filter(x =>
                    (x.client ? ((x.client.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.numeUtilizator ? ((x.numeUtilizator.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.aplicatie ? ((x.aplicatie.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.ipUtilizator ? ((x.ipUtilizator.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                );
        }

        //toate, legale, ilegale
        switch (this.state.checked) {
            case 1:
                break;
            case 2:
                listaDeAfisat = listaDeAfisat.filter(x => x.client !== null);
                break;
            case 3:
                listaDeAfisat = listaDeAfisat.filter(x => x.client === null);
                break;
            default:
        }

        const columns = [
            {
                name: 'Aplicatie',
                selector: row => row.aplicatie,
                sortable: true
            },
            {
                name: 'IP',
                selector: row => row.ipUtilizator,
                sortable: true
            },
            {
                name: 'Nume',
                selector: row => row.numeUtilizator,
                sortable: true
            },
            {
                name: 'Data',
                selector: row => new Date(row.dataAccesare).toLocaleString("ro"),
                sortable: true
            },
            {
                name: 'Client',
                selector: row => row.client,
                sortable: true
            },
            {
                name: 'Server client',
                selector: row => row.server,
                sortable: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Linii pe pagina',
            rangeSeparatorText: 'din',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Toate',
        };

        return (
            <div>
                <div className="mb-1" >
                    <div style={{ display: "inline-block", width: "30%" }}>
                        <h4>Monitorizare accesare aplicaţii</h4>
                        <div className="mb-1">
                            <label htmlFor="Client" className="form-label">Filtru:</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="client"
                                placeholder="aplicatie, IP sau client"
                                onChange={(e) => this.setState({ filtru: e.target.value })}
                            />
                        </div>
                    </div>

                    <div style={{ display: "inline-block", verticalAlign: "bottom", position: "relative", top: "-3px", marginLeft: "20px", zIndex: 2 }} >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <DatePicker
                                            style={{ position: "fixed", zIndex: 1, width: "100px" }}
                                            selected={this.state.dataStart}
                                            onChange={(e) => { this.setState({ dataStart: e }) }}
                                            //showTimeSelect
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </td>
                                    <td>
                                        <DatePicker
                                            style={{ position: "fixed", zIndex: 1, width: "100px" }}
                                            selected={this.state.dataStop}
                                            onChange={(e) => { this.setState({ dataStop: e }) }}
                                            //showTimeSelect
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary"
                                            onClick={() => { this.handleButonPerioadaClick() }}
                                        >Filtrează perioadă</button>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary"
                                            onClick={() => { this.handleButonResetPerioadaClick() }}
                                        >Resetează perioadă</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div className="form-check" style={{ display: "inline-block" }}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => this.setState({ legale: "toate", checked: 1 })} checked={this.state.checked === 1 ? true : false} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                toate
                                </label>
                        </div>
                        <div className="form-check" style={{ display: "inline-block", marginLeft: "15px" }}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => this.setState({ legale: "legale", checked: 2 })} checked={this.state.checked === 2 ? true : false} />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                legale
                                </label>
                        </div>
                        <div className="form-check" style={{ display: "inline-block", marginLeft: "15px" }}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={(e) => this.setState({ legale: "ilegale", checked: 3 })} checked={this.state.checked === 3 ? true : false} />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                ilegale
                                </label>
                        </div>
                        <button
                            style={{ marginLeft: "15px" }}
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={this.afiseazaPopup}>
                            Afişează traceIP
                            </button>
                    </div>

                    {this.state.loaderVizibil
                        ? <div style={{ width: "100%", textAlign: "center" }}>
                            {this.state.loaderVizibil
                                ? <div className="spinner-border text-dark" role="status" style={{ display: "inline-block" }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : null}
                        </div>
                        : this.state.gridVizibil ?
                            <DataTable
                                style={{ position: "fixed" }}
                                columns={columns}
                                data={listaDeAfisat}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                defaultSortFieldId={4}
                                defaultSortAsc={false}
                                fixedHeader
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={(e) => { this.state.aplicatieSelectata ? this.setState({ aplicatieSelectata: null }) : this.handleSelectRow(e.selectedRows[0]); }}
                            />
                            : null}
                </div>

                {this.state.aplicatieSelectata && this.state.trace ?
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Trace IP {this.state.aplicatieSelectata.ipUtilizator}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <table>
                                        <tbody>
                                            {this.state.trace.split("\r\n")
                                                .map((paragraf) =>
                                                    <tr style={{ margin: "0", fontSize: "12px" }}>
                                                        {paragraf.split(';')
                                                            .map((celulul) =>
                                                                <td key={celulul} style={{ border: "1px solid black", padding: "0 5px 0 5px" }}>{celulul}
                                                                </td>
                                                            )}
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}