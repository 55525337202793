import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';
import './NavMenu.css';
import { navigate } from '@reach/router';//routerul lui Ciprian
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            selectat: ''
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        //console.log("NavMenu props", this.props);

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom mb-3" light>
                    <Container>
                        <nav className="navbar navbar-expand-lg navbar-light ">
                            <a className="navbar-brand" onClick={() => navigate('./video-manual')}>Video Manual</a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarNav" style={{ marginLeft: "30px" }}>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className={"nav-link" + (this.state.selectat === 'autentificare' ? " active" : "")}
                                            onClick={() => this.setState({ selectat: 'autentificare' }, () => navigate('./'))}>Autentificare </a>
                                    </li>
                                    {
                                        this.props.autentificat ?
                                            <li className="nav-item">
                                                <a className={"nav-link" + (this.state.selectat === 'videoManual' ? " active" : "")}
                                                    onClick={() => this.setState({ selectat: 'videoManual' }, () => navigate('./video-manual'))}>VideoManual</a>
                                            </li>
                                            : null
                                    }
                                    {
                                        this.props.autentificat && localStorage.getItem("role").toLowerCase() === "administrator" ?
                                            <li className="nav-item dropdown">
                                                <a className={"nav-link dropdown-toggle" + (this.state.selectat === 'manageriat' ? " active" : "")}
                                                    onClick={() => this.setState({ selectat: 'manageriat' }, () => navigate('./monitorizare'))}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                    Administrare
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className={"dropdown-item" + (this.state.selectat === 'video' ? " active" : "")}
                                                        onClick={() => this.setState({ selectat: 'video' }, () => navigate('./video'))}>
                                                        Monitorizare manual video
                                                    </a>
                                                    <a className={"dropdown-item" + (this.state.selectat === 'aplicatii' ? " active" : "")}
                                                        onClick={() => this.setState({ selectat: 'aplicatii' }, () => navigate('./aplicatii'))}>
                                                        Monitorizare aplicaţii
                                                    </a>
                                                    <a className={"dropdown-item" + (this.state.selectat === 'clienti' ? " active" : "")}
                                                        onClick={() => this.setState({ selectat: 'clienti' }, () => navigate('./clienti'))}>
                                                        Administrare clienţi
                                                    </a>
                                                    <a className={"dropdown-item" + (this.state.selectat === 'utilizatori' ? " active" : "")}
                                                        onClick={() => this.setState({ selectat: 'utilizatori' }, () => navigate('./utilizatori'))}>
                                                        Administrare utilizatori
                                                    </a>
                                                </div>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </nav>
                        <div className="nav-item">
                            <button
                                style={{ marginLeft: "15px" }}
                                type="button"
                                className="btn btn-sm btn-outline-secondary float-right"
                                onClick={() => {
                                    localStorage.setItem("userName", null);
                                    localStorage.setItem("role", null);
                                    localStorage.setItem("autok", null);
                                    delete axios.defaults.headers.common['Authorization'];
                                    this.props.setAutentificare(false);
                                    navigate("./");
                                }}>
                                LogOut
                            </button>
                        </div>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
