import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Autentificare } from './components/Autentificare';
import { Monitorizare } from './components/Monitorizare';
import { VideoManual } from './components/VideoManual';
import MonitorizareVideo from './components/MonitorizareVideo/MonitorizareVideo';
import MonitorizareAplicatii from './components/MonitorizareAplicatii/MonitorizareAplicatii';
import MonitorizareClienti from './components/Clienti/Clienti';
import Utilizatori from './components/Utilizatori/Utilizatori';
import NotFound from './components/NotFound';
import { Router/*, navigate*/ } from '@reach/router';//routerul lui Ciprian

import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    state = {
        autentificat: false
    }

    setAutentificare = (stare) => {
        this.setState({ autentificat: stare });
    }

    render() {
        return (
            <Layout autentificat={this.state.autentificat} setAutentificare={this.setAutentificare} >
                <Router primary={false}>
                    <Autentificare path='/' setAutentificare={this.setAutentificare} />
                    <VideoManual path='/video-manual' />
                    <Monitorizare path='/monitorizare' />
                    <MonitorizareVideo path='/video' />
                    <MonitorizareAplicatii path='/aplicatii' />
                    <MonitorizareClienti path='/clienti' />
                    <Utilizatori path='/utilizatori' />
                    <NotFound path='/not-found' default />
                </Router>
            </Layout>
        );
    }
}
