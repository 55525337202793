import axios from 'axios';

export default {
    getDateVideo(folder) {
        return new Promise((resolve, reject) => {

            const getListaVideo = axios.get("./api/VideoManual/GetListaFisiere/" + folder);

            axios.all([getListaVideo]).then(
                axios.spread((responseLV) => {
                    let raspuns = {
                        listaVideo: responseLV.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateAccesareVideo(data) {
        return new Promise((resolve, reject) => {

            var getListaAccesariVideo = axios.get("./api/Monitorizare/GetAccesariVideoLaData/" + data);

            axios.all([getListaAccesariVideo]).then(
                axios.spread((responseLAV) => {
                    let raspuns = {
                        listaAccesariVideo: responseLAV.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateAccesareVideoPerioada(dataSta, dataSto) {
        return new Promise((resolve, reject) => {

            var getListaAccesariVideoPerioada = axios.get("./api/Monitorizare/GetAccesariVideoPerioada/" + dataSta + "/" + dataSto);

            axios.all([getListaAccesariVideoPerioada]).then(
                axios.spread((responseLAVP) => {
                    let raspuns = {
                        listaAccesariVideo: responseLAVP.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateAccesareAplicatii(data) {
        return new Promise((resolve, reject) => {
            var getListaAccesariAplicatii = axios.get("./api/Monitorizare/GetAccesariAplicatiiLaData/" + data);

            axios.all([getListaAccesariAplicatii]).then(
                axios.spread((responseLAA) => {
                    let raspuns = {
                        listaAccesariAplicatii: responseLAA.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getDateAccesareAplicatiiPerioada(dataSta, dataSto) {
        return new Promise((resolve, reject) => {

            var getListaAccesariAplicatiiPerioada = axios.get("./api/Monitorizare/GetAccesariAplicatiiPerioada/" + dataSta + "/" + dataSto);

            axios.all([getListaAccesariAplicatiiPerioada]).then(
                axios.spread((responseLAAP) => {
                    let raspuns = {
                        listaAccesariAplicatii: responseLAAP.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getListaClienti(data) {
        return new Promise((resolve, reject) => {

            var getListaClienti = axios.get("./api/Clienti/GetClienti");

            axios.all([getListaClienti]).then(
                axios.spread((responseLC) => {
                    let raspuns = {
                        listaClienti: responseLC.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    },
    getListaUtilizatori() {
        return new Promise((resolve, reject) => {

            var getListaUtilizatori = axios.get("./api/Utilizatori/GetUtilizatori");
            var getListaClienti = axios.get("./api/Clienti/GetClienti");
            var getListaGrupuri = axios.get("./api/Utilizatori/GetGrupuri");

            axios.all([getListaUtilizatori, getListaClienti, getListaGrupuri]).then(
                axios.spread((responseLU, responseLC, responseLG) => {
                    let raspuns = {
                        listaUtilizatori: responseLU.data,
                        listaClienti: responseLC.data,
                        listaGrupuri: responseLG.data
                    }

                    resolve(raspuns);
                })
            ).catch((error) => {
                reject(error);
            });
        });
    }
}