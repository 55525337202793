import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Utilitare from '../Content/Utilitare';
import { navigate } from '@reach/router';
import axios from 'axios';

export class Autentificare extends Component {
    static displayName = Autentificare.name;
    constructor() {
        super();
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    state = {
        loggedIn: false
    }

    //componentDidMount() {
    //    localStorage.setItem("userName", null);
    //    localStorage.setItem("role", null);
    //    localStorage.setItem("autok", null);
    //    delete axios.defaults.headers.common['Authorization'];
    //    this.props.setAutentificare(false);
    //}

    autentificare = () => {
        Utilitare.loginToken('/api/Account/Login', {
            UserName: this.state.userName
            , Password: this.state.password
            //, Email: 'Admin@ats.com.ro'
        }).then((raspuns) => {
            this.setState({ loggedIn: true }, () => {
                this.props.setAutentificare(true);
                navigate(raspuns);
            });
        }).catch(() => {
            alert("A aparut o problema la logare. Contactati administratorul.");
            this.setState({ loggedIn: false }, () => { this.props.setAutentificare(false); });
        });
    }

    logOut = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "/api/Account/Logout/"
        }).then((response) => {
            localStorage.clear();
            delete axios.defaults.headers.common['Authorization'];
            this.props.setAutentificare(false);
            navigate('./');
        }, (error) => {
            alert("A aparut o problema la delogare. Contactati administratorul.");
        }).catch((error) => {
            console.log(error);
        });
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.autentificare();
            //this.setState({ inputValue: event.target.value });
        }
    }

    render() {
        //console.log("state", this.state);

        return (
            <div>
                <h3>Autentificare</h3>
                <p style={{ color: "red" }}>Puteţi folosi credenţialele cu care vă autentificaţi în aplicaţii.</p>
                <div className="mb-3">
                    <label htmlFor="userName" className="form-label">Nume utilizator</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="userName"
                        //placeholder="Admin"
                        onChange={(e) => this.setState({ userName: e.target.value })}
                        onKeyPress={this.onKeyUp}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Parola</label>
                    <input
                        type="password"
                        className="form-control form-control-sm"
                        id="password"
                        //placeholder="ATS@123"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        onKeyPress={this.onKeyUp}
                    />
                </div>

                <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.autentificare}
                >Autentificare</button>
                <button
                    style={{ marginLeft: "5px" }}
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.logOut}
                >LogOut</button>
            </div>
        );
    }
}
