import React, { Component } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import service from '../components/Date';
import { navigate } from '@reach/router';

export class VideoManual extends Component {
    state = {
        video: "01. Autentificare",
        directorSelectat: "_"
    }

    static displayName = VideoManual.name;

    componentDidMount() {
        this.getFisiere(this.state.directorSelectat);
    }

    getFisiere = (folder) => {
        service.getDateVideo(folder).then(r => {
            if (r && r.listaVideo) {
                if (folder === "_") {
                    this.setState({
                        listaFisiere: r.listaVideo.listaFisiere,
                        listaDirectoare: r.listaVideo.listaDirectoare,
                        directorSelectat: r.listaVideo.listaDirectoare[0]
                    });
                } else {
                    this.setState({
                        listaFisiere: r.listaVideo.listaFisiere,
                        listaDirectoare: r.listaVideo.listaDirectoare,
                        directorSelectat: folder
                    });
                }
                //console.log("r", r);
            }
        }).then(() => {
            this.trimiteEmail();
        })
            .catch(error => {//in caz de eroare
                //console.log(error);
                alert("Nu aveţi drepturile necesare pentru a accesa aceste resurse.");
                navigate("./");
            })
    }

    onClickNume = (nume) => {
        this.setState({ video: nume }, () => { this.trimiteEmail() });
    }

    trimiteEmail = () => {
        axios({
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "/api/VideoManual/ScrieAccesareVideo/" + this.state.video + "/" + parseInt(localStorage.getItem("usderID"))
        }).catch((error) => {
            console.log(error);
        });
    }

    onChangeDirector = (valoare) => {
        console.log(valoare);
        this.setState({ directorSelectat: valoare }, () => { this.getFisiere(valoare); });
    }

    render() {
        console.log("Home state", this.state);

        let listaF = this.state.listaFisiere
            ? this.state.listaFisiere.map(nume =>
                <li
                    className="list-group-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onClickNume(nume)}
                    key={nume}
                >
                    {nume}
                </li>
            )
            : null;

        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: "640px" }}>
                                <div style={{ width: "600px" }}>
                                    <p style={{ textAlign: "justify" }}>Informaţiile de identificare a contribuabililor ce apar in aceste videoclipuri demonstrative au fost generate automat şi sunt fictive. Orice asemănare cu realitatea este pur întâmplătoare.</p>
                                </div>
                            </td>
                            <td>
                                <div className="form-group">
                                    <select className="form-control" id="exampleFormControlSelect1" onChangeCapture={(e) => this.onChangeDirector(e.target.value)}>
                                        {
                                            this.state.listaDirectoare
                                                ? this.state.listaDirectoare.map((nume, index) =>
                                                    <option key={index} value={nume}>{nume}</option>
                                                )
                                                : <option />
                                        }
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{this.state.video}</p>
                                <video
                                    width="640"
                                    height="480"
                                    style={{ marginRight: "5px" }}
                                    controls src={"./Media/" + this.state.directorSelectat + "/" + this.state.video + ".mp4"}>
                                    Your browser does not support the video tag.
                            </video>
                            </td>
                            <td>
                                <div style={{ height: "480px", overflow: "auto" }}>
                                    <ul className="list-group">
                                        {listaF}
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
