import axios from "axios";
import { navigate } from '@reach/router';

export default {
    loginToken(url, loginData) {
        return new Promise((resolve, reject) => {
            axios.post(url, loginData)
                .then((response) => {
                    if (response === undefined) {
                        reject("notOk");
                    }
                    //linia urmatoare adauga implicit tokenul la toate requesturile axios de dupa autentificare
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                    localStorage.setItem('userName', response.data.username);
                    localStorage.setItem('role', response.data.role);
                    localStorage.setItem('autok', response.data.accessToken);
                    localStorage.setItem('id', response.data.userID);

                    var rezultatAuth = './video-manual';

                    switch (response.data.role.toLowerCase()) {
                        case 'administrator':
                            rezultatAuth = './monitorizare';
                            break;
                        case 'operator':
                            rezultatAuth = './video-manual';
                            break;
                        default:
                            rezultatAuth = './video-manual';
                    }

                    resolve(rezultatAuth);
                })
                .catch((e) => {
                    reject("notOk");
                    //console.log("Eroare Autentificare.jsx handleAutentificare", e)
                });
        })
    },
    validareData(dataIntrare) {
        //ATENTIE!
        //formatul este zzllaaaa, fara puncte
        //returneaza null daca data e aiurea sau data in format yyyy.mm.dd
        var data = "";
        if (dataIntrare)
            data = dataIntrare.replaceAll(".", "");
        else
            return null;

        var zi = parseInt(data.substring(0, 2));
        var luna = parseInt(data.substring(2, 4));
        var an = parseInt(data.substring(4, 8));

        if (luna > 12)
            return null;
        var luni = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if ((an % 4 === 0 && an % 100 !== 0) || an % 400 === 0)
            luni = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        var ziMax = luni[luna];

        if (zi > ziMax)
            return null;
        if (an > new Date().getFullYear())
            return null;

        var rezultat = "" + an + "." + luna + "." + zi;

        if (isNaN(an) || isNaN(luna) || isNaN(zi)) {
            return null;
        }

        return rezultat;
    }
}