import React, { Component } from 'react';
//import service from '../components/Date';
import 'bootstrap/dist/css/bootstrap.css';
//import DataTable from 'react-data-table-component';
import MonitorizareVideo from './MonitorizareVideo/MonitorizareVideo';
import MonitorizareAplicatii from './MonitorizareAplicatii/MonitorizareAplicatii';
import Clienti from './Clienti/Clienti';
import { navigate } from '@reach/router';

export class Monitorizare extends Component {
    static displayName = Monitorizare.name;
    state = {
        componentaActiva: 'MonitorizareVideo'
    }

    //componentDidMount() {
    //    if (!localStorage.getItem("role") || localStorage.getItem("role") !== "Administrator")
    //        navigate("./autentificare");
    //}

    handleComponentaActiva = (comp) => {
        this.setState({ componentaActiva: comp });
    }

    render() {
        //console.log("monitorizare state", this.state);

        var deAfisat = <MonitorizareVideo />;

        switch (this.state.componentaActiva) {
            case 'video':
                deAfisat = <MonitorizareVideo />;
                break;
            case 'aplicatii':
                deAfisat = <MonitorizareAplicatii />;
                break;
            case 'clienti':
                deAfisat = <Clienti />;
                break;
            default:
                deAfisat = <MonitorizareVideo />;
        }

        return (
            <div>
                {deAfisat}
            </div>
        );
    }
}