import React, { Component } from 'react';
import service from '../../components/Date';
import 'bootstrap/dist/css/bootstrap.css';
import DataTable from 'react-data-table-component';
import { navigate } from '@reach/router';
import axios from 'axios';

export default class Utilizatori extends Component {
    static displayName = Utilizatori.name;
    state = {
        utilizatorSelectat: {
            idUtilizatori: 0,
            nume: null,
            prenume: null,
            cnp: null,
            email: null,
            numeUtilizator: null,
            parola: null,
            refGrup: 2,
            stare: 1,
            refClienti: 0
        },
        confirmareParola: null,
        filtru: null,
        gridVizibil: false,
        formularAdaugareVizibil: false,
        loaderVizibil: false
    }

    componentDidMount() {
        this.setState({ loaderVizibil: true }, () => { this.getListaUtilizatori(); });
    }

    getListaUtilizatori = () => {
        service.getListaUtilizatori().then(r => {
            if (r && r.listaUtilizatori) {
                this.setState({
                    listaUtilizatori: r.listaUtilizatori,
                    listaClienti: r.listaClienti,
                    listaGrupuri: r.listaGrupuri,
                    loaderVizibil: false
                });
            }
        }).then(() => {
            this.setState({
                gridVizibil: true,
                formularAdaugareVizibil: false,
                loaderVizibil: false
            });
        }).catch(error => {//in caz de eroare
            alert("Nu aveţi drepturile necesare pentru a accesa aceste resurse.");
            this.setState({ loaderVizibil: false }, () => { navigate('./video-manual'); });
        })
    }

    handleSelectRow = (date) => {
        this.setState({ utilizatorSelectat: date });
    }

    handleStergere = () => {
        if (!this.state.utilizatorSelectat) {
            alert("Nu aţi selectat niciun utilizator.");
            return;
        }

        if (window.confirm('Sunteţi sigur că doriţi să dezactivaţi utilizatorul selectat?')) {
            axios({
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: "/api/Utilizatori/StergeUtilizator/" + this.state.utilizatorSelectat.idUtilizatori
            }).then((raspuns) => {
                if (raspuns === "NotOk") {
                    alert("Utilizatorul nu a fost şters.");
                } else {
                    this.setState({ loaderVizibil: true }, () => { this.getListaUtilizatori(); });
                }
            }
            ).catch((error) => {
                alert("Utilizatorul nu a fost dezactivat");
                console.log(error);
            });
        }
    }

    handleModificare = () => {
        if (!this.state.utilizatorSelectat) {
            alert("Nu aţi selectat niciun utilizator.");
            return;
        }

        this.setState({ confirmareParola: this.state.utilizatorSelectat.parola, gridVizibil: false, formularAdaugareVizibil: true });
    }

    handleAdaugare = () => {
        var ut = {
            idUtilizatori: 0,
            nume: null,
            prenume: null,
            cnp: null,
            email: null,
            numeUtilizator: null,
            parola: null,
            refGrup: 2,
            stare: 1,
            refClienti: 0
        };

        this.setState({ confirmareParola: null, utilizatorSelectat: ut, gridVizibil: false, formularAdaugareVizibil: true });
    }

    handleRenunta = () => {
        var ut = {
            idUtilizatori: 0,
            nume: null,
            prenume: null,
            cnp: null,
            email: null,
            numeUtilizator: null,
            parola: null,
            refGrup: 2,
            stare: 1,
            refClienti: 0
        }

        this.setState({ confirmareParola: null, utilizatorSelectat: ut, gridVizibil: true, formularAdaugareVizibil: false });
    }

    handleSalveaza = () => {
        console.log("utilizatorSelectat", this.state.utilizatorSelectat);

        if (!this.state.utilizatorSelectat.parola || !this.state.confirmareParola || this.state.utilizatorSelectat.parola !== this.state.confirmareParola) {
            alert("Parola nu se confirmă.");
            return;
        }

        if (!this.state.utilizatorSelectat.numeUtilizator || this.state.utilizatorSelectat.numeUtilizator === "") {
            alert("Nu aţi introdus numele de utilizator.");
            return;
        }

        if (!this.state.utilizatorSelectat.refClienti || this.state.utilizatorSelectat.refClienti === 0) {
            alert("Nu aţi selectat un client.");
            return;
        }

        axios({
            data: this.state.utilizatorSelectat,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: "./api/Utilizatori/UpsertUtilizator/"
        }).then((raspuns) => {
            if (raspuns === "NotOk") {
                alert("Utilizatorul nu a fost adăugat/modificat.");
            } else {
                alert("Utilizatorul a fost salvat.");
                this.setState({ loaderVizibil: true }, () => { this.getListaUtilizatori(); });
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    render() {
        console.log("Clienti state", this.state)

        const grupuri = this.state.listaGrupuri
            ? this.state.listaGrupuri.map(x => {
                return <option key={x.idGrupuri} value={x.idGrupuri}>{x.denumire}</option>
            })
            : null;

        const clienti = this.state.listaClienti
            ? this.state.listaClienti.map(x => {
                return <option key={x.idClienti} value={x.idClienti}>{x.denumireClient}</option>
            })
            : null;

        const formularAdaugare = <div className="container" style={this.stil}>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">
                    <label htmlFor="nume" className="form-label">Nume</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="nume"
                        id="nume"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.nume : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.nume = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
                <div className="col col-sm-3">
                    <label htmlFor="prenume" className="form-label">Prenume</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="prenume"
                        placeholder="prenume"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.prenume : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.prenume = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">
                    <label htmlFor="cnp" className="form-label">cnp</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="cnp"
                        placeholder="CNP"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.cnp : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.cnp = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
                <div className="col col-sm-3">
                    <label htmlFor="email" className="form-label">E-mail</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="email"
                        placeholder="email"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.email : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.email = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">
                    <label htmlFor="numeUtilizator" className="form-label">Nume utilizator</label>
                    <input
                        style={{ borderColor: "tomato" }}
                        type="text"
                        className="form-control form-control-sm"
                        id="numeUtilizator"
                        placeholder="numeUtilizator"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.numeUtilizator : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.numeUtilizator = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
                <div className="col col-sm-3">
                    <label htmlFor="parola" className="form-label">Parola</label>
                    <input
                        style={{ borderColor: "tomato" }}
                        type="password"
                        className="form-control form-control-sm"
                        id="parola"
                        placeholder="parola"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.parola : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.parola = e.target.value;
                            this.setState({ utilizatorSelectat: ut });
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">

                </div>
                <div className="col col-sm-3">
                    <label htmlFor="parola" className="form-label">Confirmă parola</label>
                    <input
                        style={{ borderColor: "tomato" }}
                        type="password"
                        className="form-control form-control-sm"
                        id="parola"
                        placeholder="parola"
                        value={this.state.confirmareParola ? this.state.confirmareParola : null}
                        onBlur={(e) => {
                            this.setState({ confirmareParola: e.target.value });
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">
                    <label htmlFor="grup" className="form-label">Grup</label>
                    <select
                        style={{ borderColor: "tomato" }}
                        id="grup"
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.refGrup : null}
                        onChange={(e) => {
                            console.log(e.target.value)

                            var ut = this.state.utilizatorSelectat;
                            ut.refGrup = parseInt(e.target.value);
                            this.setState({ utilizatorSelectat: ut });
                        }}>
                        {grupuri ? grupuri : null}
                    </select>
                </div>
                <div className="col col-sm-3">
                    <label htmlFor="client" className="form-label">Client</label>
                    <select
                        style={{ borderColor: "tomato" }}
                        id="client"
                        className="form-select form-select-sm"
                        aria-label="client"
                        value={this.state.utilizatorSelectat ? this.state.utilizatorSelectat.refClienti : null}
                        onChange={(e) => {
                            var ut = this.state.utilizatorSelectat;
                            ut.refClienti = parseInt(e.target.value);
                            this.setState({ utilizatorSelectat: ut });
                        }}>
                        <option key={0} value={0}>Alege client</option>
                        {clienti ? clienti : null}
                    </select>
                </div>
            </div>
            <div className="row" style={{ margin: "6px 0 2px 0" }}>
                <div className="col col-sm-3">
                    <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-success" onClick={() => { this.handleSalveaza() }}>Salvează</button>
                    <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-danger" onClick={() => { this.handleRenunta() }}>Renunţă</button>
                </div>
            </div>
        </div>

        var listaDeAfisat = this.state.listaUtilizatori;

        if (this.state.listaUtilizatori) {
            if (this.state.filtru)
                listaDeAfisat = listaDeAfisat.filter(x =>
                    (x.nume ? ((x.nume.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.prenume ? ((x.prenume.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.cnp ? ((x.cnp.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.email ? ((x.email.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.numeUtilizator ? ((x.numeUtilizator.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.siruta ? ((x.siruta.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                );
        }

        const columns = [
            {
                name: 'Nume',
                selector: row => row.nume,
                sortable: true
            },
            {
                name: 'Prenume',
                selector: row => row.prenume,
                sortable: true
            },
            {
                name: 'User',
                selector: row => row.numeUtilizator,
                sortable: true
            },
            {
                name: 'CNP',
                selector: row => row.cnp,
                sortable: true
            },
            {
                name: 'E-mail',
                selector: row => row.email,
                sortable: true
            },
            {
                name: 'Grup',
                selector: row => this.state.listaGrupuri && this.state.listaGrupuri.find(x => x.idGrupuri === row.refGrup) ? this.state.listaGrupuri.find(x => x.idGrupuri === row.refGrup)?.denumire : null,
                sortable: true
            },
            {
                name: 'Stare',
                selector: row => row.stare,
                sortable: true
            },
            {
                name: 'Client',
                selector: row => this.state.listaClienti && this.state.listaClienti.find(x => x.idClienti === row.refClienti) ? this.state.listaClienti.find(x => x.idClienti === row.refClienti)?.denumireClient : null,
                sortable: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Linii pe pagina',
            rangeSeparatorText: 'din',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Toate',
        };

        return (
            <div>
                {this.state.formularAdaugareVizibil
                    ? null
                    : <div>
                        {this.state.gridVizibil === true ?
                            <div className="mb-3">
                                <h4>Administrare Utilizatori</h4>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100px" }}>
                                                <label htmlFor="utilizator" className="form-label">Filtru:</label>
                                            </td>
                                            <td>
                                                <input
                                                    style={{ width: "200px" }}
                                                    type="text"
                                                    className="form-control form-control-sm w-25"
                                                    id="utilizator"
                                                    placeholder="nume, prenume, cnp, email, numeUtilizator, siruta"
                                                    onChange={(e) => this.setState({ filtru: e.target.value })}
                                                />
                                            </td>
                                            <td style={{ width: "250px" }}>
                                                <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary" onClick={() => { this.handleAdaugare() }}>Adaugă</button>
                                                <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary" onClick={() => { this.handleModificare() }}>Modifică</button>
                                                <button type="button" className="btn btn-sm btn-outline-secondary" disabled={this.state.clientSelectat ? false : true} onClick={() => { this.handleStergere() }}>{this.state.clientSelectat ? (this.state.clientSelectat.activ === true ? "Dezactivează" : "Activează") : "Dezactivează"}</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                        {this.state.loaderVizibil
                            ? <div style={{ width: "100%", textAlign: "center" }}>
                                {this.state.loaderVizibil
                                    ? <div className="spinner-border text-dark" role="status" style={{ display: "inline-block" }}>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : null}
                            </div>
                            : this.state.listaUtilizatori && this.state.gridVizibil === true
                                ? <DataTable
                                    columns={columns}
                                    data={listaDeAfisat}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    defaultSortFieldId={3}
                                    defaultSortAsc={true}
                                    fixedHeader
                                    selectableRows
                                    selectableRowsSingle
                                    onSelectedRowsChange={(e) => { this.handleSelectRow(e.selectedRows[0]); }}
                                />
                                : null}
                    </div>
                }
                {this.state.formularAdaugareVizibil ? formularAdaugare : null}

            </div>
        );
    }
}