import React, { Component } from 'react';
import service from '../../components/Date';
import 'bootstrap/dist/css/bootstrap.css';
import DataTable from 'react-data-table-component';
import { navigate } from '@reach/router';
import axios from 'axios';

export default class Clienti extends Component {
    static displayName = Clienti.name;
    state = {
        clientSelectat: null,
        filtru: null,
        gridVizibil: false,
        formularAdaugareVizibil: false,
        formularModificareVizibil: false,
        loaderVizibil: false
    }

    componentDidMount() {
        this.setState({ loaderVizibil: true });
        this.getListaClienti();
    }

    getListaClienti = () => {
        service.getListaClienti().then(r => {
            if (r && r.listaClienti) {
                this.setState({
                    listaClienti: r.listaClienti,
                    loaderVizibil: false
                });
            }
        }).then(() => {
            this.setState({
                gridVizibil: true,
                formularModificareVizibil: false,
                formularAdaugareVizibil: false,
                loaderVizibil: false
            });
        }).catch(error => {//in caz de eroare
            alert("Nu aveţi drepturile necesare pentru a accesa aceste resurse.");
            this.setState({ loaderVizibil: false }, () => { navigate('./video-manual'); });
        })
    }

    handleSelectRow = (date) => {
        this.setState({ clientSelectat: date });
    }

    handleStergere = () => {
        if (!this.state.clientSelectat) {
            alert("Nu aţi selectat niciun client.");
            return;
        }

        if (window.confirm('Sunteţi sigur că doriţi să dezactivaţi clientul selectat?')) {
            this.setState({ loaderVizibil: true }, () => {
                axios({
                    headers: { "Content-Type": "application/json" },
                    method: "post",
                    url: "/api/Clienti/StergeClient/" + this.state.clientSelectat.idClienti
                }).then((raspuns) => {
                    if (raspuns === "NotOk") {
                        alert("Clientul nu a fost şters.");
                        this.setState({ loaderVizibil: false });
                    } else {
                        this.getListaClienti();
                    }
                }
                ).catch((error) => {
                    this.setState({ loaderVizibil: false }, () => { alert("Clientul nu a fost şters."); });
                    console.log(error);
                });
            });
        }
    }

    handleModificare = () => {
        if (!this.state.clientSelectat) {
            alert("Nu aţi selectat niciun client.");
            return;
        }

        this.setState({ gridVizibil: false, formularModificareVizibil: true, formularAdaugareVizibil: false });
    }

    handleAdaugare = () => {
        var client = {
            idClienti: 0,
            denumireClient: null,
            listaIpClient: null,
            mentiuni: null,
            indicativ: null
        };

        this.setState({ clientSelectat: client, gridVizibil: false, formularModificareVizibil: false, formularAdaugareVizibil: true });
    }

    handleRenunta = () => {
        this.setState({ gridVizibil: true, formularModificareVizibil: false, formularAdaugareVizibil: false, clientSelectat: null });
    }

    handleSalveaza = () => {
        if (!this.state.clientSelectat.denumireClient) {
            alert("Nu aţi completat Denumire client");
            return;
        }

        if (!this.state.clientSelectat.indicativ) {
            alert("Nu aţi completat Indicativ");
            return;
        }

        axios({
            headers: { "Content-Type": "application/json" },
            data: this.state.clientSelectat,
            method: "post",
            url: "/api/Clienti/UpsertClient/"
        }).then((raspuns) => {
            if (raspuns === "NotOk") {
                alert("Clientul nu a fost şters.");
            } else {
                this.setState({ loaderVizibil: true }, () => { this.getListaClienti(); });
            }
        }
        ).catch((error) => {
            alert("Utilizatorul nu a fost salvat in baza de date.");
            console.log(error);
        });
    }

    render() {
        console.log("Clienti state", this.state)
        console.log("Clienti props", this.props)

        const formularModificare = <div>
            <div className="mb-3">
                <label htmlFor="client" className="form-label">Denumire client</label>
                <input
                    style={{ width: "200px", borderColor: "tomato" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="client"
                    placeholder="client"
                    value={this.state.clientSelectat ? this.state.clientSelectat.denumireClient : null}
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.denumireClient = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="listaIpClient" className="form-label">IP client</label>
                <input
                    style={{ width: "200px" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="listaIpClient"
                    placeholder="IP client"
                    value={this.state.clientSelectat ? this.state.clientSelectat.listaIpClient : null}
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.listaIpClient = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="mentiuni" className="form-label">Menţiuni</label>
                <input
                    style={{ width: "200px" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="mentiuni"
                    placeholder="menţiuni"
                    value={this.state.clientSelectat ? this.state.clientSelectat.mentiuni : null}
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.mentiuni = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="indicativ" className="form-label">Indicativ</label>
                <input
                    style={{ width: "200px", borderColor: "tomato" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="indicativ"
                    placeholder="indicativ"
                    value={this.state.clientSelectat ? this.state.clientSelectat.indicativ : null}
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.indicativ = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary btn-outline-success" onClick={() => { this.handleSalveaza() }}>Salvează</button>
            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary btn-outline-danger" onClick={() => { this.handleRenunta() }}>Renunţă</button>
        </div>

        const formularAdaugare = <div>
            <div className="mb-3">
                <label htmlFor="client" className="form-label">Denumire client</label>
                <input
                    style={{ width: "200px", borderColor: "tomato" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="client"
                    placeholder="client"
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.denumireClient = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="listaIpClient" className="form-label">IP client</label>
                <input
                    style={{ width: "200px" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="listaIpClient"
                    placeholder="Lista IP client"
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.listaIpClient = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="mentiuni" className="form-label">Menţiuni</label>
                <input
                    style={{ width: "200px" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="mentiuni"
                    placeholder="menţiuni"
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.mentiuni = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="indicativ" className="form-label">Indicativ</label>
                <input
                    style={{ width: "200px", borderColor: "tomato" }}
                    type="text"
                    className="form-control form-control-sm w-25"
                    id="indicativ"
                    placeholder="indicativ"
                    onChange={(e) => {
                        var cl = this.state.clientSelectat;
                        cl.indicativ = e.target.value
                        this.setState({ clientSelectat: cl })
                    }}
                />
            </div>
            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary btn-outline-success" onClick={() => { this.handleSalveaza() }}>Salvează</button>
            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary btn-outline-danger" onClick={() => { this.handleRenunta() }}>Renunţă</button>
        </div>

        var listaDeAfisat = this.state.listaClienti;

        if (this.state.listaClienti) {
            if (this.state.filtru)
                listaDeAfisat = listaDeAfisat.filter(x =>
                    (x.denumireClient ? ((x.denumireClient.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.listaIpClient ? ((x.listaIpClient.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.mentiuni ? ((x.mentiuni.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                    || (x.indicativ ? ((x.indicativ.toLowerCase()).indexOf(this.state.filtru.toLowerCase()) >= 0) : false)
                );
        }

        const columns = [
            {
                name: 'Client',
                selector: row => row.denumireClient,
                sortable: true
            },
            {
                name: 'IP',
                selector: row => <p title={row.listaIpClient}>{row.listaIpClient}</p>,
                sortable: true
            },
            {
                name: 'Menţiuni',
                selector: row => <p title={row.mentiuni}>{row.mentiuni}</p>,
                sortable: true
            },
            {
                name: 'Activ',
                selector: row => row.activ ? "da" : "nu",//"îhî" : "nţ",
                sortable: true
            },
            {
                name: 'Indicativ',
                selector: row => row.servere.indicativ,
                sortable: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Linii pe pagina',
            rangeSeparatorText: 'din',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Toate',
        };

        return (
            <div>
                { this.state.formularAdaugareVizibil || this.state.formularModificareVizibil
                    ? null
                    : <div>
                        <div className="mb-3">
                            <h4>Administrare Clienţi</h4>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "100px" }}>
                                            <label htmlFor="Client" className="form-label">Filtru:</label>
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: "200px" }}
                                                type="text"
                                                className="form-control form-control-sm w-25"
                                                id="client"
                                                placeholder="client, ip, mentiuni, indicativ"
                                                onChange={(e) => this.setState({ filtru: e.target.value })}
                                            />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary" onClick={() => { this.handleAdaugare() }}>Adaugă</button>
                                            <button style={{ marginRight: "5px" }} type="button" className="btn btn-sm btn-outline-secondary" onClick={() => { this.handleModificare() }}>Modifică</button>
                                            <button type="button" className="btn btn-sm btn-outline-secondary" disabled={this.state.clientSelectat ? false : true} onClick={() => { this.handleStergere() }}>{this.state.clientSelectat ? (this.state.clientSelectat.activ === true ? "Dezactivează" : "Activează") : "Dezactivează"}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {
                            this.state.clientSelectat ?
                                <p style={{ fontSize: "12px" }}>Link: https://tutoriale.ats-net.ro/api/Monitorizare/ScrieAccesare/{this.state.clientSelectat.indicativ}/{"{remoteIpAddress}"}/{"{denumire aplicaţie}"}/{"{numeUtilizator}"}</p>
                                : <p style={{ fontSize: "12px" }}>Link:</p>
                        }

                        {this.state.loaderVizibil
                            ? <div style={{ width: "100%", textAlign: "center" }}>
                                {this.state.loaderVizibil
                                    ? <div className="spinner-border text-dark" role="status" style={{ display: "inline-block" }}>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : null}
                            </div>
                            : this.state.listaClienti && this.state.gridVizibil === true
                                ? <DataTable
                                    columns={columns}
                                    data={listaDeAfisat}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    defaultSortFieldId={2}
                                    defaultSortAsc={false}
                                    fixedHeader
                                    selectableRows
                                    selectableRowsSingle
                                    onSelectedRowsChange={(e) => { this.handleSelectRow(e.selectedRows[0]); }}
                                />
                                : null}
                    </div>
                }

                {this.state.formularModificareVizibil ? formularModificare : null}
                {this.state.formularAdaugareVizibil ? formularAdaugare : null}
            </div>
        );
    }
}